    import React, { useEffect, useState } from 'react';
    import axios from 'axios';
    import { Link } from 'react-router-dom';

    import { ToastContainer, toast } from 'react-toastify';
    import { useUser } from '../../Services/UserContext.js';
    import { BASE_URL } from '../../Services/index.js';
    import 'react-toastify/dist/ReactToastify.css';

    import Sidebar from '../sidebar/Sidebar';
    import Loader from '../../UserSide/Components/LoaderComponent';

    const InvoicePage = () => {
        const [processed_invoices, setProcessedInvoices] = useState([]);
        const [loading, setLoading] = useState(true);
        const [isSidebarOpen, setIsSidebarOpen] = useState(true);
        const [searchTerm, setSearchTerm] = useState(''); 
        const [filteredInvoices, setFilteredInvoices] = useState([]);
        const { userData } = useUser();

        useEffect(() => {
            const fetchProcessedInvoices = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/invoices`, {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    });

                    setProcessedInvoices(response.data.processed_invoices);
                    setFilteredInvoices(response.data.processed_invoices); 
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching processed invoices:', error);
                    setLoading(false);
                }
            };
            fetchProcessedInvoices();
        }, [userData]);

        
        useEffect(() => {
            const filtered = processed_invoices.filter(invoice => {
                const ticket_id = invoice.ticket_id ? invoice.ticket_id.toString().toLowerCase() : ''; 
                const user_name = invoice.user?.name.toLowerCase() || '';
                const user_email = invoice.user?.email.toLowerCase() || '';
                const user_phone = invoice.user?.phone || '';

                return (
                    user_name.includes(searchTerm.toLowerCase()) ||
                    user_email.includes(searchTerm.toLowerCase()) ||
                    user_phone.includes(searchTerm) || 
                    ticket_id.includes(searchTerm.toLowerCase())
                );
            });
            setFilteredInvoices(filtered);
        }, [searchTerm, processed_invoices]);

        const handleSidebarToggle = (isOpen) => {
            setIsSidebarOpen(isOpen);
        };

        // const handleDeleteInvoice = async (invoiceId) => {
        //     try {
        //         await axios.delete(`${BASE_URL}/invoices/${invoiceId}`, {
        //             headers: {
        //                 Authorization: `Bearer ${userData?.token}`,
        //             },
        //         });

        //         setProcessedInvoices(processed_invoices.filter(invoice => invoice.id !== invoiceId));
        //         setFilteredInvoices(filteredInvoices.filter(invoice => invoice.id !== invoiceId));
        //         toast.success('Invoice deleted successfully!');
        //     } catch (error) {
        //         console.error('Error deleting invoice:', error);
        //         toast.error('Failed to delete invoice.');
        //     }
        // };

        return (
            <div className="container-fluid">
                <div className="row">
                    <Sidebar onToggleSidebar={handleSidebarToggle} />
                    <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                        <div className="dashboard-content ">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Invoices</h1>
                        </div>
                            <input
                                type="text"
                                placeholder="Search invoices..."
                                className="form-control mb-4"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="table-responsive mt-2">
                                    <table className="table table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Sr</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th className='col-md-1 text-center'>Quantity</th>
                                                <th className='col-md-1 text-center'>Price</th>
                                                <th className='col-md-1 text-center'>Date</th>
                                                <th className='col-md-1 text-center'>Question</th>
                                                <th className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredInvoices.length === 0 ? (
                                                <tr>
                                                    <td colSpan="10" className="text-center">No invoices found</td>
                                                </tr>
                                            ) : (
                                                filteredInvoices.map((invoice, index) => (
                                                    <tr key={invoice.id}>
                                                        <td className='align-middle'>{index + 1}</td>
                                                        <td className='align-middle'>{invoice.ticket_id}</td>
                                                        <td className='align-middle'>{invoice.user ? invoice.user.name : 'N/A'}</td>
                                                        <td className='align-middle'>{invoice.user ? invoice.user.email : 'N/A'}</td>
                                                        <td className='align-middle'>{invoice.user ? invoice.user.phone : 'N/A'}</td>
                                                        <td className='text-center align-middle'>{invoice.total_quantity}</td>
                                                        <td className='text-center align-middle'>{Number(invoice.total_price).toFixed(2)}</td>
                                                        <td className='align-middle'>{new Date(invoice.created_at).toLocaleDateString()}</td>
                                                        <td className='text-center align-middle'>{invoice.is_correct}</td>

                                                        <td className="text-center align-middle">
                                                            <div className=' d-flex gap-2 g-0 justify-content-center'>
                                                            <Link to={`/dashboard/invoices/${invoice.id}`} className="btn btn-info btn-sm">
                                                                <i className="bi bi-eye"></i>
                                                            </Link>
                                                            {/* <button 
                                                                onClick={() => handleDeleteInvoice(invoice.id)} 
                                                                className="btn btn-danger btn-sm"
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <ToastContainer /> 
            </div>
        );
    };

    export default InvoicePage;
