import React from 'react';
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import '../../css/privacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="home-header">
            <Header />
            <div className=''>
                <section className="home-header inner-page home-header-about-us ">
                    <div className='privacy-policy privacy-mobile-view'>
                        <h3 className='text-left about-us-heading' style={{ fontSize: "2rem" }}>Privacy Policy:</h3>
                        <p className='about-us-text'>
                            Enaam takes the privacy of its users very seriously. We strive to provide a safe and secure user experience. This Privacy Policy details the online data collection and usage policies and practices that apply to the Enaam website and does not apply to information we collect in any other form.
                        </p>
                        <p className='about-us-text'>
                            The Enaam site contains links to other websites over which we have no control. We are not responsible for the privacy policies or practices of those websites to which you choose to link from our site. We encourage you to review the privacy policies of those sites to understand how they collect, use, and share your information.
                        </p>

                        <br></br>
                        <h3 className='about-us-heading'>Usage and Information Collection by Enaam:</h3>
                        <p className='about-us-text'>
                            We gather information about our users' participation in Enaam, including personal details necessary for entering our lucky draws, such as your name, email address, and phone number. This information is logged to enhance the overall user experience and ensure the functionality of the site.
                        </p>
                        <br></br>
                        <h3 className='about-us-heading'>Our Use of Your Information:</h3>
                        <p className='about-us-text'>
                            We use the information we gather on Enaam for the purpose of operating and improving our website, creating a positive user experience, and facilitate participation in our lucky draws.
                        </p>
                        <br />
                        <h3 className='about-us-heading'>Disclosure of Personal Information to Third Parties:</h3>
                        <p className='about-us-text'>
                            We do not disclose your personally identifiable information to third parties for their promotional purposes. We use third-party service providers only for managing specific functionalities related to our services, such as sending emails or managing user support. These providers are not authorized to use your information for their promotional purposes.
                        </p>
                        <p className='about-us-text'>
                            We may disclose information if legally required to do so, if requested by a governmental entity, or if we believe in good faith that such action is necessary to comply with legal requirements or protect the safety of our users.
                        </p>
                        <p className='about-us-text'>
                            In the event of a sale or merger of our business, your information may be part of the assets transferred. You will be notified via email or through a prominent notice posted on the Enaam website if such circumstances arise.
                        </p>

                        <br />
                        <h3 className='about-us-heading'>Security of the Profile Information:</h3>
                        <p className='about-us-text'>
                            We have implemented commercially reasonable technical and organizational measures designed to secure your personal information from unauthorized access, use, alteration, or disclosure. However, we cannot guarantee absolute security against unauthorized access.
                        </p>

                        <br />
                        <h3 className='about-us-heading'>Use of Material:</h3>
                        <p className='about-us-text'>
                            The contents of Enaam, such as text, graphics, images, logos, button icons, software, and other material, are protected under Pakistan and foreign copyright, trademark, and other laws. All material is the property of Amnah Mall or its content suppliers or clients. The collection, arrangement, and assembly of all content on this Website are the exclusive property of Amnah Mall and are protected by copyright laws. You may not sell, modify, reproduce, distribute, or use any material from the Website without our prior written consent.
                        </p>

                        <br />
                        <h3 className='about-us-heading'>Children's Use of Enaam:</h3>
                        <p className='about-us-text'>
                            The Enaam website is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.
                        </p>

                        <br />
                        <h3 className='about-us-heading'>Privacy Policy Modifications:</h3>
                        <p className='about-us-text'>
                            If we decide to materially change our Privacy Policy for Enaam, we will post those changes through a prominent notice on the website 30 days prior to the change taking place so that you will always know what information we gather, how we might use that information, and to whom we will disclose it.
                        </p>
                        <p className='about-us-text'>
                            If at any time you have questions or concerns about this Privacy Policy or believe that we have not adhered to this Privacy Policy, please feel free to use our feedback form. We will use commercially reasonable efforts to promptly answer your question or resolve your problem.
                        </p>

                        <br />
                        <h3 className='about-us-heading'>Contact Information:</h3>
                        <p className='about-us-text'>
                            Enaam is operated by Amnah Mall, with its principal place of business at Amnah Mall, Link Road, Model Town, Lahore, Pakistan. Amnah Mall (Pvt.) Ltd. is the legal entity that determines the purposes and means of processing the information gathered at www.Enaam.pk , You can also contact us at support@Enaam.com
                        </p>

                        <p className='text-left about-us-text mb-4'>
                            Thank you for using Enaam!
                        </p>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;