import React , { useState , useEffect} from 'react';
import { Colors } from '../../globals/colors';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "../../css/ProductList.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css'; 
import { useUser } from '../../../Services/UserContext';
import CustomModal from '../CustomModal';
import Login from '../../../Dashboard/Login';
import Register from '../../../Dashboard/Register';

const ProductCard = ({ product }) => {
    const navigate = useNavigate();
    const { addToCart, userData } = useUser();
    const toggleComponent = () => {
        setShowLogin(!showLogin);
    };


  const [isModalOpen, setModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  

  const handleModalClose = () => setModalOpen(false);


    const percentage = (product.sold / product.quantity) * 100;
    let progressBarClass = 'progress-green';  
    let buttonStyle = {};
    let buttonText = 'Add to Cart';

    if (percentage >= 70 && percentage < 90) {
        progressBarClass = 'progress-orange';
    } else if (percentage >= 90 && percentage < 100) {
        progressBarClass = 'progress-red';
    } else if (percentage === 100) {
        progressBarClass = 'progress-red';
        buttonStyle = { pointerEvents: 'none', opacity: 0.6 };
        buttonText = 'Entries Completed';
    }

    const addProductToCart = async (item) => {
        if (!userData?.token) {
            // toast.error('Please login to add product to cart');
            setModalOpen(true); // Show the modal directly
    //   toast.error('Please login to add product to cart'); 
            return;
        }

        const payload = {
            product_id: item.id,
            quantity: 1,
            price: item.price
        };

        try {
            const response = await addToCart(payload);
            if (response.status === 200) {
                toast.success('Product added to cart');
                navigate('/cart');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="col-md-12 tikker glow">
            <ToastContainer />
            <div className="row">
                <div className="col-md-3">
                    <div className="p-0 position-relative">
                        <Link to={`/product/${product.id}`} className="zoom d-block">
                            <img className="card-img-bottom d-block" src={product.reward.image} style={{ borderRadius: 20 }} alt="Card image cap" />
                        </Link>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card-body course-details pb-0" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%", alignItems: 'flex-start' }}>
                        <Link to={`/product/${product.id}`} style={{ textDecoration: "none", color: Colors.themeColor }}>
                            <h3 className="mb-sm-2 title">
                                <span className="d-block-res ">Win</span> {product.reward.name}
                            </h3>
                        </Link>
                        <p className="mb-3" style={{ textAlign: "left" }}>{product.description}</p>

                        <div className="mt-md-1 mb-lg-0 mb-4 d-flex">
                            <Link className={"prizeDetailsButton"} to={`/product/${product.id}`}>Prize Details</Link>
                            <button 
                                className={"addCartButton"} 
                                style={buttonStyle} 
                                onClick={() => { addProductToCart(product) }}
                            >
                                {buttonText}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 col-12 hide-on-mobile prizes-values">
                    <div className="value-status">
                        <div>
                            <h6 className="mb-0 card-heading">
                                <p>{product.sold} Entries out of {product.quantity}</p>
                            </h6>
                            <div className="progress">
                                <div className={progressBarClass} role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div>
                            <img
                                src={require("../../images/share_ma.png")}
                                style={{ cursor: 'pointer', width: 35, height: 35 }}
                                className='img-fluid mt-2'
                                alt=""
                                onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=https://enaam.pk/', 'facebook-share-dialog', 'width=800,height=600')}
                            />
                        </div>
                    </div>
                    <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
                    <div>
                        {showLogin ? <Login /> : <Register />}
                        <button
                            onClick={toggleComponent}
                            className="btn btn-link mt-2 "
                            style={{   cursor: 'pointer' , color:Colors.themeColor , fontWeight: 'bold' ,fontSize: '14px'}}
                        >
                            {showLogin ? 'Don\'t have an account?' : 'Already have an account? Go to Login'}
                        </button>
                    </div>
                </CustomModal>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
