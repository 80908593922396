import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../Services';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; 

import Header from '../UserSide/Components/HeaderComponent';
import Footer from '../UserSide/Components/FooterCompnent';


const ResetPassword = () => {
    const location = useLocation();
    const { user } = location.state || {};
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        password: '',
        password_confirmation: '', 
    });


    useEffect(() => {
        setFormData({
            name: user.name,
            email: user.email,
            phone: user.phone,
            password: '',
            password_confirmation: '',
        });
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (formData.password !== formData.password_confirmation) {
            toast.error('Passwords do not match.');
            setLoading(false);
            return;
        }else if (formData.password.length < 8) {
            toast.error('Password must be at least 8 characters long.');
            setLoading(false);
            return;
        }
        
        try {
            const response = await axios.put(`${BASE_URL}/update-user-details/${user.id}`, formData);
            
            if (response.data.success) {
                navigate('/');
                toast.success('User details have been successfully updated.');
           
            } else {
                toast.error('Failed to update user details.');
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            toast.error('Failed to update user details.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="App">
            <Header />
            <div className="container mt-5">
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-8 col-lg-12 mt-5">
                        <div className="card p-4 shadow-lg" style={{ borderRadius: "10px" }}>
                            <h3 className="mb-4 text-center" style={{ color: '#35B5FF' }}>Reset Your Password</h3>
                            <form className='text-start' onSubmit={handleSubmit}>
                                <div className="mb-3 text-start">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input 
                                        type="text" 
                                        id="name" 
                                        name="name" 
                                        className="form-control" 
                                        value={formData.name} 
                                        onChange={handleChange} 
                                        required 
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input 
                                        type="email" 
                                        id="email" 
                                        name="email" 
                                        className="form-control" 
                                        value={formData.email} 
                                        onChange={handleChange} 
                                        required 
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input 
                                        type="text" 
                                        id="phone" 
                                        name="phone" 
                                        className="form-control" 
                                        value={formData.phone} 
                                        onChange={handleChange} 
                                        required 
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">New Password </label>
                                    <input 
                                        type={showPassword ? "text" : "password"} 
                                        id="password" 
                                        name="password" 
                                        className="form-control" 
                                        value={formData.password} 
                                        onChange={handleChange}
                                        required
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }} 
                                    />
                                     <i
                                        className={`bi ${showPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5'}`}
                                        style={{ cursor: 'pointer',  position: 'absolute', top: '67.5%', right: '34px', transform: 'translateY(-50%)'  }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password_confirmation" className="form-label">Confirm New Password</label>
                                    <input 
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="password_confirmation" 
                                        name="password_confirmation" 
                                        className="form-control" 
                                        value={formData.password_confirmation} 
                                        onChange={handleChange} 
                                        required
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }}
                                    />
                                    <i
                                    className={`bi ${showConfirmPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5 '} `}
                                    style={{ cursor: 'pointer', position: 'absolute', top: '82.5%', right: '34px', transform: 'translateY(-50%)' }}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                />
                                </div>
                                <button type="submit" 
                                    className="btn btn-primary w-100"
                                    style={{
                                        backgroundColor: '#35B5FF',
                                        borderColor: '#35B5FF',
                                        borderRadius: '20px',
                                        padding: '10px',
                                        fontSize: '16px',
                                    }}
                                    disabled={loading}>
                                    {loading ? 'Updating...' : 'Update Details'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer profile={false}/>
            <ToastContainer />
        </div>
    );
};

export default ResetPassword;
