
    import React, { useEffect, useState } from 'react';
    import { toast, ToastContainer } from 'react-toastify';
    import { useNavigate } from 'react-router-dom';
    import { useUser } from '../Services/UserContext.js';
    import { BASE_URL } from '../Services/index.js';
    import axios from 'axios';

    import Header from '../UserSide/Components/HeaderComponent'; 
    import Footer from '../UserSide/Components/FooterCompnent'; 

    import 'react-toastify/dist/ReactToastify.css';

    function ProfileScreen() {
        const { userData, logout } = useUser();
        const navigate = useNavigate();
        const [user, setUser] = useState();
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [phone, setPhone] = useState('');
        const [password, setPassword] = useState('');
        const [passwordConfirmation, setPasswordConfirmation] = useState('');
        const [showPassword, setShowPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal state

        useEffect(() => {
            if (userData) {
                setUser(userData || {});
                setName(userData.name || '');
                setEmail(userData.email || '');
                setPhone(userData.phone || '');
            }
        }, [userData]);

        const handleDelete = async () => {
            if(userData.role=="admin"){
                toast.error('Admin cannot be deleted.');
                return;
            }else{
            try {
                console.log(userData.id);
                await axios.delete(`${BASE_URL}/users/${userData.id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    
                });
                logout();
                navigate('/');
                toast.success('Account deleted successfully.');
            } catch (error) {
                console.error(error);
                toast.error('Error deleting account.');
            }
        }
        };
        const handleUpdate = async (e) => {
            e.preventDefault();

            const updatedUserData = {
                name,
                email,
                phone,
            };

            if (password && passwordConfirmation) {
                updatedUserData.password = password;
                updatedUserData.password_confirmation = passwordConfirmation;
            }

            try {
                const response = await axios.put(`${BASE_URL}/user`, updatedUserData, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                });
                setUser(response.data);
                toast.success('Profile updated successfully!');
            } catch (error) {
                console.error(error);
                toast.error('Error updating profile.');
            }
        };


        return (
            <div className="App pt-5">
                <Header />
                <div className="container-fluid mt-5">
                    <ToastContainer />
                    <div className="row justify-content-center text-start mt-5">
                        <div className="col-md-11 col-lg-10 mt-5" >
                            <div className="card rounded p-4 glow" style={{marginBottom:"30px",borderRadius:10}}>
                                <div className="card-header text-start glow mt-3">
                                    <h1 className="mb-0 text-left">{user?.name}</h1>
                                </div>
                                <div className="card-body" > 
                                    <form onSubmit={handleUpdate}>
                                        <div className="mt-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                className="form-control"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <input
                                                type="text"
                                                id="phone"
                                                className="form-control"
                                                placeholder="Phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input
                                                type={showPassword ? "text" : "password"} 
                                                id="password"
                                                className="form-control"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <i
                                                className={`bi ${showPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5'}`}
                                                style={{ cursor: 'pointer',  position: 'absolute', top: '68%', right: '48px', transform: 'translateY(-50%)'  }}
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="passwordConfirmation" className="form-label">Confirm Password</label>
                                            <input
                                                type={showConfirmPassword ? "text" : "password"}
                                                id="passwordConfirmation"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                            />
                                            <i
                                                className={`bi ${showConfirmPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5 '} `}
                                                style={{ cursor: 'pointer', position: 'absolute', top: '81.5%', right: '48px', transform: 'translateY(-50%)' }}
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                        <button className='btn btn-danger ml-3' onClick={() => { 
                                            logout();
                                            navigate("/") }}>Logout</button>

                                 {userData?.role !== "admin" && (   
                                        <button className='btn btn-danger float-end ml-3' 
                                        onClick={() => setShowDeleteModal(true)}>Delete My Account</button>
                                    )}    
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                {showDeleteModal && (
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Deletion</h5>
                                    <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete your account?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={handleDelete}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer profile={false} /> 
            </div>
        );
    }

    export default ProfileScreen;
